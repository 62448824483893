import React, { Component } from 'react';
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import CardBody from "react-bootstrap/CardBody";
import CardHeader from "react-bootstrap/CardHeader";
import ListGroup from "react-bootstrap/ListGroup";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import TableHeadDay from './TableHeadDay';
import ExModalWorkorder from '../modals/ModalWorkorder';


export default class TerminWorkList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            time: Date.now(),
            errorResponse: true,
            fetchDate: null,
            data: [],
            count: 0,
            myHash: null,
            token: null
        };
        this.nocode = '';
        this.dataAdress = null;
        this.dataBody = null;
        this.token = '';

        this.setCalendar = this.setCalendar.bind(this);
        this.onChangesDay = this.onChangesDay.bind(this);
        this.resetCalendar = this.resetCalendar.bind(this);
        this.onReload = this.onReload.bind(this);
        this.removeTimer = this.removeTimer.bind(this);
        this.onDatesInsert = this.onDatesInsert.bind(this);

        
    }

    _fetchData = async () => {
        clearTimeout(this.timeOut);
        let responseJson;

        this.errorResponse = true;

        const oldData = this.state.response;

        var body = 'token=' + this.state.token;

        if(this.state.fetchDate) {
            body += `&params[date]=` + this.state.fetchDate

        } else if(sessionStorage.getItem('myDate')) {
            body += `&params[date]=` + sessionStorage.getItem('myDate')
        }

        if(localStorage.getItem('calendarid')) {
            body += `&params[calendars]=` + localStorage.getItem('calendarid');
        }

        body += "&params[status]=1";
        body += "&params[storno]=no";
        body += "&params[hash]=" + this.state.myHash;

        //console.log(new Date().toString(), 'body', body);


        try {
            const response = await fetch(localStorage.getItem('server') + '/calendar/day/', {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },            
                method: 'POST',
                body: body,
            });
            //console.log('response', response.clone().text());
            responseJson = await response.json();
            this.errorResponse = false;
            //console.log(new Date().toString(), 'responseJson', responseJson);

        } catch (e) {
            // Handle the timeout error here
            //console.log(new Date().toString(), 'Timeout error:', e);            
            responseJson = oldData;
        }

        if(responseJson.status && responseJson.data !== 'nochange') {
            //console.log(new Date().toString(), 'responseJson', responseJson, this.errorResponse);
            this.setState({
                isLoading: true,
                data: responseJson, 
                time: Date.now(), 
                myHash: responseJson.parameters.dataHash,
                errorResponse: this.errorResponse 
            });
        } else if(!responseJson.status) {
            //console.log(new Date().toString(), 'responseJson', responseJson, this.errorResponse);
            this.errorResponse = true;

        }
        this.timeOut = setTimeout(() => {
            this.updateTime();
        }, 60000);
        
    };

    loadData() {
        //localStorage.getItem('server') + '/calendar/day/';
    }

    updateTime() {
        //console.log('TerminWorkList updateTime', this.errorResponse);
        this.setState({
            time: Date.now(),
            errorResponse: this.errorResponse
        });
    }

    setCalendar(event) {
        //console.log(event.target.value);
        localStorage.setItem('calendarid', event.target.value);
        this._fetchData();
    }

    resetCalendar() {
        localStorage.removeItem("calendarid");
        this._fetchData();

    }

    onChangesDay(event) {
        //console.log('onChangesDay', event.target.value);
        this.setState({ 
            isLoading: false,
            fetchDate: event.target.value
        });
    }

    onReload() {
        this._fetchData();
    }

    onDatesInsert() {
       //console.log('onDatesInsert');
        this._fetchData();
    }

    removeTimer() {
        //console.log('TerminWorkList updateTime clear');
        //clearTimeout(this.timeOut);

    }

    componentDidUpdate() {
        this._fetchData();
    }

    componentDidMount() {
        if(sessionStorage.getItem('myService')) {
            const response = sessionStorage.getItem('myService');
            const responseJson = JSON.parse(response);
            this.token = responseJson.Ergebnis;
            this.type = this.props.type;
            this.setState({
                time: Date.now(),
                myService: responseJson,
                token: responseJson.Ergebnis,
                fetchDate: this.props.fetchDate
            });
        }

    }

    render() {

        if(this.state.isLoading && localStorage.getItem('calendarid')) {

            const xHours = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30];

            var x = 0;
            var termine = '';

            const datacalendar = this.state.data.data;
            const action = this.state.data.action;
            const user = JSON.parse(sessionStorage.getItem('myUser'));

           //console.log(new Date().toString(), datacalendar.calendar[0]);


            const dates = datacalendar.calendar[0].dates[datacalendar.datum];
           //console.log('data', datacalendar.calendar[0].data);

            var data = null;
            if(datacalendar.calendar[0].data) {
                data = datacalendar.calendar[0].data[datacalendar.datum];
            }
            //console.log('data', data);

            var currentTime = new Date(this.state.time);
    
            var nowtime = currentTime.getHours().toString().padStart(2, '0') + ":" + currentTime.getMinutes().toString().padStart(2, '0');

            //console.log("datacalendar", datacalendar.calendar[0]);

            return (
                
                <Card>
                    <Button
                            className="mb-2 pt-3 pb-3"
                            color="primary"
                            onClick={this.resetCalendar}
                            >
                                Kalenderauswahl
                    </Button>                    
               
                    <Alert color="danger" show={this.state.errorResponse} className="position-absolute start-50 translate-middle" style={{top: '120px', zIndex: '1080'}}
 >                      <h3><Spinner color="danger" /> Verbindungsfehler!</h3>
                        Verbindung verloren. Die daten werden nicht aktualisiert.<br />
                        Wiederaufbau wird versucht.
                    </Alert>     



                    <ListGroup flush="true" className='mb-5'>

                    <TableHeadDay type='day' action={action} onChangesDay={this.onChangesDay} period={datacalendar.period} lable={datacalendar.calendar[0].lable + ' ' + datacalendar.period[0].dayName + ' ' + datacalendar.header + ' (Stand ' + nowtime + ')'}/>


                    {
                        !datacalendar.calendar[0].data
                        
                        ? <Alert 
                            className='m-2'
                            variant='danger'
                            ><h4>Dieses Auto / Dieser Kalender ist heute nicht besetzt!</h4>
                        </Alert>

                        : <Container fluid>
                            <Row>
                                <Col className='m-2'>

                                    {
                                        data[1].date 
                                        
                                        ? <Alert variant='info'>Vormittag<br />
                                            {   
                                                data[1].user_primary !== '0' 
                                                ? ', ' + user[data[1].user_primary].namekurz 
                                                : 'unbesetzt / interne Fahrt'
                                            }
                                            {   
                                                data[1].user_secondary !== '0' 
                                                ? ', ' + user[data[1].user_secondary].namekurz 
                                                : null
                                            }
                                        </Alert> 
                                        
                                        : <Alert 
                                            className='m-2'
                                            variant='danger'
                                            ><h4>Vormittag nicht besetzt!</h4>
                                        </Alert>
                                    }
                                    
                                    
                                </Col>
                                <Col className='m-2'>

                                    {
                                        data[2].date 
                                        
                                        ? <Alert variant='info'>Nachmittag<br />
                                            {
                                                data[2].user_primary !== '0' 
                                                ? ', ' + user[data[2].user_primary].namekurz 
                                                : 'unbesetzt / interne Fahrt'
                                            }
                                            {   
                                                data[2].user_secondary !== '0' 
                                                ? ', ' + user[data[2].user_secondary].namekurz 
                                                : null
                                            }
                                        </Alert> 
                                        
                                        : <Alert 
                                            className='m-2'
                                            variant='danger'
                                            ><h4>Nachmittag nicht besetzt!</h4>
                                        </Alert>
                                    }

                                </Col>
                            </Row>
                        </Container>

                        
                    }

                    {
                        // eslint-disable-next-line array-callback-return
                        xHours.map((index) => {
                            if(dates[index] && dates[index].id && (['80','55','51','50','40'].includes(dates[index].statusid) && dates[index].sign === null)) {
                                //console.log('TerminWorkList', dates[index].statusid);
                               //console.log('TerminWorkList aktuell', dates[index].statusid, dates[index]);
                                x++;
                                return (
                                    <ExModalWorkorder key={index} listTyp={1} element={dates[index]} calendardate={datacalendar.calendar[0].data[datacalendar.datum]} removeTimer={this.removeTimer} onReload={this.onReload} loadData={this.props.loadData} onClosedModal={this.props.onClosedModal} onDatesInsert={this.onDatesInsert} />
                                );
                            }                                
                        })                            
                    }

                    <hr />

                    {
                        // eslint-disable-next-line array-callback-return
                        xHours.map((index) => {
                            if(dates[index] && dates[index].id && (['20','10','1'].includes(dates[index].statusid) && dates[index].sign === null)) {
                                //console.log('TerminWorkList', dates[index].statusid);
                               //console.log('TerminWorkList next', dates[index].statusid, dates[index]);
                                x++;
                                return (
                                    <ExModalWorkorder key={index} listTyp={2} element={dates[index]} calendardate={datacalendar.calendar[0].data[datacalendar.datum]} removeTimer={this.removeTimer} onReload={this.onReload} loadData={this.props.loadData} onClosedModal={this.props.onClosedModal} onDatesInsert={this.onDatesInsert} />
                                );
                            }                                
                        })                            
                    }                    
                        <hr />
                    {
                        // eslint-disable-next-line array-callback-return
                        xHours.map((index) => {

                            if(((dates[index] && dates[index].id && ['90','70','60','65'].includes(dates[index].statusid)) || (dates[index] && dates[index].id && ['80'].includes(dates[index].statusid) && dates[index].sign !== null))) {
                                //console.log('TerminWorkList 2', dates[index].statusid);
                               //console.log('TerminWorkList erledigt', dates[index].statusid, dates[index]);
                                x++;
                                return (
                                    <ExModalWorkorder key={index} listTyp={3} element={dates[index]} calendardate={datacalendar.calendar[0].data[datacalendar.datum]} removeTimer={this.removeTimer} onReload={this.onReload} loadData={this.props.loadData} onClosedModal={this.props.onClosedModal} onDatesInsert={this.onDatesInsert} />
                                );
                            }                                
                        })                            
                    }

                    {
                        x === 0 && !datacalendar.calendar[0].data
                        
                        ? <Alert className='m-2' variant='danger'><h4>Keine geplanten Termine</h4></Alert> 
                        
                        : termine
                    }
                    <Alert variant="warning" className='m-2'>
                        <h4>Status Pause:</h4>
                        <p>Ist nur für kurze Pausen wie Mittag oder Termin wärend Wartezeit gedacht!</p>
                        <p>Für Termine die Unterbrochen werden und nicht am gleichen Tag fortgesetzt werden bitte den Status E-Teil bestellt nutzen!</p>
                         
                    </Alert>                            
                    </ListGroup>
                </Card>
                
            );
        } else if(this.state.isLoading) {
            const datacalendar = this.state.data.data;

            return (
                <Card className="my-2" >
                    <Alert color="danger" show={this.state.errorResponse} className="position-absolute start-50 translate-middle" style={{top: '120px', zIndex: '1080'}}
 >                      <h3><Spinner color="danger" /> Verbindungsfehler!</h3>
                        Verbindung verloren. Die daten werden nicht aktualisiert.<br />
                        Wiederaufbau wird versucht.
                    </Alert>  
                    <CardHeader>
                        Kalender wählen!
                    </CardHeader>
                    <CardBody>
                        <div className="row">
                        {
                            datacalendar.calendar.map((element, index) => {

                                return (
                                    <div className="col-6 p-2" key={index}>
                                        <Button
                                            color="primary"
                                            className="col-12"
                                            onClick={this.setCalendar}
                                            value={element.id}
                                        >
                                            {element.lable}<br /> {element.name}
                                        </Button>
                                    </div>
                                );

                            })
                        }

                        </div>
                    </CardBody>
                </Card>
            );
        } else {
            return (
                    <div  className="position-absolute top-50 start-50 translate-middle">
                        <Spinner animation="border" role="status" >
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
            );
        }



    }
}