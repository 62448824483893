import React, { Component } from 'react';
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import ModalBody from "react-bootstrap/ModalBody";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import FloatingLabel from "react-bootstrap/FloatingLabel";

import DateCreate from '../forms/DateCreate'
import SearchDebitor from '../forms/SearchDebitor'



export default class TerminCreate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            time: Date.now(),
            data: [],
            count: 0,
            hash: null,
            debitor: false,
            debitorId: '',
            debitorSearch: '',
            debitorName: '',
            debitorData: null,
            debitorList: [],
            calBlock: false,
            calDate: [

            ],
            myService: null,
            token: null
        };
        this.nocode = '';
        this.debitorIdSearch = '';
        this.debitorSearch = '';
        this.daytime = {1:'Vormittag', 2:'Nachmittag'};


        this.onDebitorIdChange = this.onDebitorIdChange.bind(this);
        this.onDebitorSearchChange = this.onDebitorSearchChange.bind(this);
        this.onSelectDebitor = this.onSelectDebitor.bind(this);
        this.onChangeDebitorData = this.onChangeDebitorData.bind(this);
        this.onSelectNoDebitor = this.onSelectNoDebitor.bind(this);
        this.onSelectBlock = this.onSelectBlock.bind(this);
        this.test = this.test.bind(this);
    }


    _fetchData = async () => {
        var body = null;


        if(this.state.debitorId.length === 8 && this.state.debitorId === this.debitorIdSearch) {
            body = '';
            body += `params[search]=` + this.state.debitorId
            this.debitorIdSearch = '';
        }

        if(this.state.debitorSearch.length >= 3 && this.state.debitorSearch === this.debitorSearch) {
            body = '';
            body += `params[search]=` + this.state.debitorSearch
            this.debitorSearch = '';

        }

        //console.log(body);

        

        if(body !== null) {

            body += "&token=" + this.token;

            const response = await fetch(localStorage.getItem('server') + '/debitor', {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },            
                method: 'POST',
                body: body,
            });
            const responseJson = await response.json();
           //console.log(responseJson);
            if(!responseJson.status) {
                sessionStorage.clear();
                window.location.href = '/';                 
            } else if(responseJson.data.length === 1 && this.state.debitor !== false) {
                this.setState({ debitorData: responseJson.data[0]});
            } else {
                this.setState({ debitorList: responseJson.data});
            } 

        }

    };

    searchById() {
        this._fetchData();
    }

    onDebitorIdChange(event) {
        this.debitorIdSearch = event.target.value;
        this.setState({
            debitorId: event.target.value,
        });
    }

    onDebitorSearchChange(event) {
        this.debitorSearch = event.target.value;
        this.setState({
            debitorSearch: event.target.value,
        });
    }

    onSelectDebitor(event) {
        this.debitorIdSearch = event.target.dataset.value;
        this.setState({
            debitorId: event.target.dataset.value,
            debitor: true
        });        
    }

    onSelectNoDebitor(event) {
        //console.log(event.target);

        this.setState({
            debitorData: {id:'noDebitor'}
        });         
    }

    onSelectBlock(event) {
        this.setState({
            calBlock: true
        });    
    }

    onChangeDebitorData(event) {
        if(this.state.debitorData !== null) {
            //console.log(event.target);

        }        
    }

    componentDidUpdate() {
        this._fetchData();

    }

    componentDidMount() {
        if(sessionStorage.getItem('myService')) {
            const response = sessionStorage.getItem('myService');
            const responseJson = JSON.parse(response);
            this.token = responseJson.Ergebnis;
            this.setState({
                myService: responseJson,
                token: responseJson.Ergebnis,
            });
        } else {
            this.token = '999988887777666655554444333322221111';
            this.setState({
                token: '999988887777666655554444333322221111',
            });
        }
    }

    test() {
        return 1;
    }
    //          <TerminCreate  calDate={props.date} calStart={tStart[props.rowStart]} calId={element.id} element={element} />


    render() {
       //console.log('TimeCreate', this.props);

        const currentTime = new Date(this.props.calDate);


        var nowtime = currentTime.getHours().toString().padStart(2, '0') + ":" + currentTime.getMinutes().toString().padStart(2, '0') + " Uhr";

        if(nowtime === '01:00 Uhr') {
            nowtime = this.daytime[this.props.daytime];
        } else if(nowtime === '02:00 Uhr') {
            nowtime = this.daytime[this.props.daytime];
        }
    
        var options = { weekday: 'long', day: 'numeric', month: 'long' };

        var nowdate = currentTime.toLocaleDateString("de-DE", options);

        //currentTime = new Date(dataCalender.datum);

        if(this.state.debitorData !== null) {
            return (
                <DateCreate 
                    calDate={this.props.calDate} 
                    calId={this.props.calId} 
                    maxDayTime={this.props.maxDayTime} 
                    maxTime={this.props.maxTime} 
                    daytime={this.props.daytime} 
                    debitorData={this.state.debitorData} 
                    toggle={this.props.toggle}
                />
            )
        } else if(this.state.calBlock === true) {
            return (
                <>
                Zeit Block
                </>
            )
        } else if(this.state.calBlock === 'XXX') {
            return (

                <ModalBody className="pt-0">

                    <Form inline="true">
                        <Row className="bg-info pt-3 pb-3">
                            <Col xs={8}>
                                <h3 className="pt-2 ps-3">{nowdate} {nowtime}</h3> {this.state.backButtonValue} {this.state.buttonValue}
                            </Col>
                        </Row>                    
                        <Row className="pt-3">
                            <Col md={8}>
                                <FloatingLabel 
                                    className="mb-3"
                                    label="Kundensuche (Kunden Nr., Name, Firma)"
                                    
                                    >
                                    <Form.Control 
                                        type="text" 
                                        id="search"  
                                        onChange={this.onDebitorSearchChange}
                                        value={this.state.debitorSearch}
                                        isInvalid={this.state.debitorSearch < 60}
                                        autoComplete='off'
                                        placeholder="Kundensuche (Kunden Nr., Name, Firma)"
                                        name='debitorSearch'
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col md={2}>
                                <Form.Group>
                                <Button className='m-2' name="noDebitor" size='lg' onClick={this.onSelectNoDebitor}>neuer Kunde</Button>
                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                <Form.Group>
                                <Button className='m-2' name="noDebitor" size='lg' onClick={this.onSelectBlock}>Block</Button>
                                </Form.Group>
                            </Col>

                        </Row>
                    </Form>
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Nr</th>
                                <th>Name</th>
                                <th>Adresse</th>
                                <th>Ort {this.state.debitorList.length}</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                this.state.debitorList.length > 0 ?
                                this.state.debitorList.map((element, index) => {
                                    return (
                                        <tr key={index} className={element.textColor} onClick={this.onSelectDebitor}>
                                            <td data-value={element.id}>{element.id}</td>
                                            <td data-value={element.id}>{element.firstname} {element.lastname}</td>
                                            <td data-value={element.id}>{element.address}</td>
                                            <td data-value={element.id}>{element.zip} {element.city}</td>

                                        </tr>
                                    );

                                })
                                : <tr>
                                    <td colSpan="4" className='h3 text-center'> <br /> Bitte erst die Kundensuche nutzen! <br /> <br /> Sollte kein Kunde gefunden werden, bitte auf "neuer Kunde" klicken. <br />  <br /> </td>
                                </tr>
                            }
                        </tbody>
                    </Table>

                </ModalBody>
    )
        } else {
            return (
                <SearchDebitor 
                    nowdate={nowdate}
                    nowtime={nowtime}
                    state={this.state}
                    onDebitorSearchChange={this.onDebitorSearchChange}
                    onSelectNoDebitor={this.onSelectNoDebitor}
                    onSelectBlock={this.onSelectBlock}
                    onSelectDebitor={this.onSelectDebitor}
                />

            )
        }


    }
}