import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import DateEdit from '../forms/DateEdit';
import TerminWorkorder from '../components/TerminWorkorder';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const ExModalWorkorder = (props) => {

    const {
        className
    } = props;
    
    const [modalWorkorder, setModalWorkorder] = useState(false);
    const toggleWorkorder = () => setModalWorkorder(!modalWorkorder);

    const [modalDate, setModalDate] = useState(false);
    const toggleDate = () => setModalDate(!modalDate);

    const element = props.element;

   //console.log('ExModalWorkorder', element);

    var mapsLink = `maps://?q=${element['zip']},${element['city']},${element['street']}&views=satellite,traffic&zoom=30`;

    const s1class = "col-md-1 col-sm-2 text-center ex-" + element.status + " test";

    var stempEnd = true;

    if(element.stempend !== "") {
        stempEnd = false;
    }

    //console.log("modalDate", modalDate, "modalWorkorder", modalWorkorder, "stempEnd", stempEnd)

    return (
        
        <ListGroup.Item style={{margin: '0 !important', padding: '0 !important'}}>
            {/* <div className="row" style={{ margin: '0 !important',padding: '0 !important'}}> */}
            <Row className='pb-2 bg-dark-subtle'>

                <Col className={'col-1' + s1class}>
                    <strong>{element.start}</strong><br/> 
                    <i className="bi bi-arrow-down"></i><br/> 
                    <div>{element.end}</div>
                </Col>
                <Col  className="col-md-5 col-sm-5" onClick={toggleDate} >
                    {element.order_sid} {element['name']}<br/> 
                    {element['street']}<br/> 
                    {element['zip']} {element['city']}                
                </Col>
                <Col  className="col-md-5 col-sm-5 text-end" onClick={toggleDate} >
                    <br/> 
                    <br/> 
                         
                    {   
                            element.paymethod === null 
                                ? <></>
                                : element.paymethod === '1' 
                                    ? <i className="bi bi-cash-coin fs-4"> {element.payamount} €</i> 
                                    : <i className="bi bi-credit-card-2-back-fill fs-4"> {element.payamount} €</i>
                    }
           
                </Col>
              
            </Row>

            {       
            
                props.listTyp === 2
                ? <Row className="text-end justify-content-md-end">
                    <Col
                        className="border-end border-white"
                        xs={3}
                        as={Button}
                        href={mapsLink} 
                        target="navi"
                    >
                        <i className="bi bi-pin-map fs-4"> Route</i>
                    </Col>  
                    <Col 
                        xs={3}
                        as={Button}
                        className="border-end border-white"
                        style={{ visibility: 'hidden'}}
                    >
                    
                    </Col>    
                    <Col
                        xs={3}
                        as={Button}
                        className="border-end border-white"
                        variant= {element.sign === null ? "primary" : "success"}
                        href={"sumupmerchant://pay/1.0?amount=" + element.payamount + "&currency=EUR&affiliate-key=sup_afk_ldn33EHGwAX6Kzqr9XyDJdmEHzyfvjP7&title=" + element.order_vk + "&callbackfail=samplepaymentapp%3A%2F%2F&callbacksuccess=samplepaymentapp%3A%2F%2F" }
                        style = {{ visibility: element.paymethod === null ? 'hidden' : 'block'}}
                        disabled
                    >
                        {   
                            element.paymethod === null 
                                ? <i className="bi bi-x-circle"></i> 
                                : element.paymethod === '1' 
                                    ? <i className="bi bi-cash-coin fs-4"> Bar</i> 
                                    : <i className="bi bi-credit-card-2-back-fill fs-4"> SumUp</i>
                        }
                    </Col>      
                    <Col
                        xs={3}
                        as={Button}
                        className="border-end border-white"
                        variant= {element.sign === null ? "primary" : "success"}
                        onClick={toggleWorkorder}
                        disabled={stempEnd}
                    >
                        {element.sign === null ? <i className="bi bi-pen fs-4"> Unterschrift</i> : <i className="bi bi-file-earmark-text-fill fs-4"> Auftrag</i>}
                    </Col>              
                </Row>
                : <Row className="text-end justify-content-md-end">
                    <Col
                        className="border-end border-white"
                        xs={3}
                        as={Button}
                        href={mapsLink} 
                        target="navi"
                    >
                        <i className="bi bi-pin-map fs-4"> Route</i>
                    </Col>  
                    <Col 
                        xs={3}
                        as={Button}
                        className="border-end border-white"
                        style={{ visibility: 'hidden'}}
                    >
                    
                    </Col>                  
                    <Col
                        xs={3}
                        as={Button}
                        className="border-end border-white"
                        variant= {element.sign === null ? "primary" : "success"}
                        href={"sumupmerchant://pay/1.0?amount=" + element.payamount + "&currency=EUR&affiliate-key=sup_afk_ldn33EHGwAX6Kzqr9XyDJdmEHzyfvjP7&title=" + element.order_vk + "&callbackfail=samplepaymentapp%3A%2F%2F&callbacksuccess=samplepaymentapp%3A%2F%2F" }
                        style = {{ visibility: element.paymethod === null ? 'hidden' : 'block'}}
                        disabled
                    >
                        {   
                            element.paymethod === null 
                                ? <i className="bi bi-x-circle"></i> 
                                : element.paymethod === '1' 
                                    ? <i className="bi bi-cash-coin fs-4"> Bar</i> 
                                    : <i className="bi bi-credit-card-2-back-fill fs-4"> SumUp</i>
                        }
                    </Col>

                    <Col
                        xs={3}
                        as={Button}
                        className="border-end border-white"
                        variant= {element.sign === null ? "primary" : "success"}
                        onClick={toggleWorkorder}
                        disabled={stempEnd}
                    >
                        {element.sign === null ? <i className="bi bi-pen fs-4"> Unterschrift</i> : <i className="bi bi-file-earmark-text-fill fs-4"> Auftrag</i>}
                    </Col>
                    

                    </Row>
            }
            <Modal fullscreen show={modalWorkorder} className={className} onHide={toggleWorkorder} onExit={props.loadData} onExited={props.onDatesInsert} >
                <TerminWorkorder ontoggle={toggleWorkorder} element={element} calendardate={props.calendardate} onExit={props.loadData} onHide={props.onClosedModal} />
            </Modal>
            <Modal fullscreen show={modalDate} className={className} onHide={toggleDate} onExited={props.onDatesInsert} >
                <DateEdit ontoggle={toggleDate} element={element} onClick={props.removeTimer} />
            </Modal>
        </ListGroup.Item>
        
    );
}

export default ExModalWorkorder;