import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function ViewDebitor(props) {

    var formData = {};
    if(props.formData.order_data === undefined) {
        formData = props.formData;
    } else {
        //formData = props.formData.order_data;
    }

   //console.log('ViewDebitor', formData);

    return (
        <Row className='mb-3'>
            <Col className='m-1 pb-0 pt-0 fs-4'>                
                    {formData.salutation || formData.debitor_title}<br />
                    {
                        formData.salutation === 'Firma' 
                            ? (<>{formData.firstname || formData.debitor_firstname}<br />{formData.lastname || formData.debitor_lastname}<br /></>) 
                            : (<>{formData.firstname || formData.debitor_firstname} {formData.lastname || formData.debitor_lastname}<br /></>)
                    }
                    {formData.address || formData.debitor_address}<br />


                    {formData.zip || formData.debitor_zipcode} {formData.city || formData.debitor_city}<br />
            </Col>
            <Col className='m-1 fs-4'>
                    {
                        formData.etage !== '' || formData.debitor_etage === undefined
                            ? (<p className="form-control-lg mb-0 pb-0 pt-0 ps-0">Etage: {formData.etage || formData.debitor_etage}</p>) 
                            : ''
                    }                    
                    {formData.telefon || formData.debitor_telefon ? formData.telefon || formData.debitor_telefon + <a type="button" className="btn btn-outline-success btn-sm" href={"tel:" + formData.telefon || formData.debitor_telefon} >anrufen</a> : ''} <br />
                    {formData.mobil || formData.debitor_mobil ? formData.mobil || formData.debitor_mobil + <a type="button" className="btn btn-outline-success btn-sm" href={"tel:" + formData.mobil || formData.debitor_mobil} >anrufen</a> : ''} <br />
                    {formData.mail || formData.debitor_mailaddress}
            </Col>
            <Col className='m-1 fs-4'>
                    Kunden Nr.: {formData.debitor_id 
                                    ? formData.debitor_id
                                    : formData.id
                                }
                    <br />

                    {
                        (formData.sid || formData.order_sid) !== ''
                            ? (<>SID: {formData.sid || formData.order_sid}<br /></>)
                            : ''
                    }
                    {
                        (formData.sid || formData.order_vk) !== ''
                            ? (<>Auftrag: {formData.vrva || formData.order_vk}<br /></>)
                            : ''
                    }                    
            </Col>                        
        </Row>
    )

}
