import React, { useState } from 'react';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import TerminCreate from '../components/TerminCreate';
import ProgressBar from 'react-bootstrap/ProgressBar';

const ExModalTerminAdd = (props) => {
    //console.log("modals/ModalDateAdd");

    const className = props.className;
  
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
  
    const tStart = {
      5: "08:00:00",
      6: "08:30:00",
      7: "09:00:00",
      8: "09:30:00",
      9: "10:00:00",
      10: "10:30:00",
      11: "11:00:00",
      12: "11:30:00",
      13: "12:00:00",
      14: "12:30:00",
      18: "13:00:00",
      19: "13:30:00",
      20: "14:00:00",
      21: "14:30:00",
      22: "15:00:00",
      23: "15:30:00",
      24: "16:00:00",
      25: "16:30:00",
      26: "17:00:00",
      27: "17:30:00",
      28: "18:00:00",
      29: "18:30:00",
      30: "19:00:00"   
    };
  
    //console.log(props.element);
    const element = props.element;

    const onMouseOver = event => {
      var el = document.getElementById(event.target.id);
      el.classList.add('plan-success')
      if(event.target.dataset.start) {
        el.innerHTML = event.target.dataset.start;
      }
    };

    const onMouseOut = event => {
      var el = document.getElementById(event.target.id);
      el.classList.remove('plan-success')
      if(event.target.dataset.start) {
        el.innerHTML = '';
      }
    };
  
    function handleDragOver(event) {
      //console.log("DateAdd DragOver to", event.target);
      //props.handleToTime(event);
      var el = document.getElementById(event.target.id);
      el.classList.add('text-bg-danger')
      event.preventDefault();
    }

    function handleDrop(event) {
      props.handleToTime(event);
      event.preventDefault();
      //console.log("DateAdd Drop to", event.dataTransfer.getData("id"), event.target.dataset.calendar, event.target.dataset.start)
      //props.handleToDrop(event.dataTransfer.getData("id"), 'cal', 'time');
      var el = document.getElementById(event.target.id);
      el.classList.remove('text-bg-danger')
    }

    function handleDragLeave(event) {
      var el = document.getElementById(event.target.id);
      el.classList.remove('text-bg-danger')
    }

    function onToggle() {
     //console.log('onTogle', props)
      if(!props.disabled) {
        toggle();
      }
    }


    if(props.type === 'notime') {

      //console.log(element);
      return (
        <td 
          key={1000 + props.timeid} 
          style={{
              padding: 0
          }}
        >
          <ProgressBar 
            style={{
                borderRadius: 'unset',
                height: '23px',
                textAlign: 'right',               
            }}
            className='bg-success position-relative user-select-none pe-auto'
            max={240}
            onClick={onToggle} 
            role="button"
          >
            <ProgressBar variant="danger" 
              now={(240 - props.usedtime)} 
              key={1}
            />
            <ProgressBar 
              variant="success" 
              now={props.usedtime} 
              key={2} 
              />
          <div className="justify-content-center d-flex position-absolute w-100 m-1 text-light" key={3} >{element.data[props.date][props.daytime].city}</div>
          </ProgressBar>
          {/* justify-content-center d-flex position-absolute w-100 m-1 text-light */}
          <Modal 
            fullscreen='xl-down' 
            size='xl' 
            show={modal} 
            toggle={toggle} 
            onHide={toggle} 

            className={className} 
            onClosed={props.onDatesInsert} 
            disabled={props.disabled} 
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header className="fs-3 alert-info" closeButton>Terminassistent {element.name}</Modal.Header>
              <TerminCreate 
                calDate={props.date} 
                daytime={props.daytime} 
                maxTime={props.maxTime}
                maxDayTime={props.maxDayTime}
                calId={element.id} 
                toggle={toggle} 
              />
          </Modal>
        </td>
      );
    } else {

      if( props.modtyp === "planner") {

         //console.log(element);
        return (
          <>
            <div 
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onDragLeave={handleDragLeave}
    
    
                id={(props.colStart * 1000 + props.timeid )}
                key={props.timeid} 
                data-typ='ziel'
                data-key={props.timeid}
                data-id={props.dindex} 
                data-calendar={element.id}
                data-date={props.date}
                data-start={tStart[props.rowStart].substring(0,5)}
                data-daytime={props.daytime}
                className={props.classElement + " pe-auto cell" } 
                
                onClick={toggle}
                onMouseEnter={event => onMouseOver(event)}
                onMouseOut={event => onMouseOut(event)}
                
                style={{
                    //gridRowStart: props.rowStart,  
                    gridRowStart: 'h' + tStart[props.rowStart].substring(0,5).replace(":", ""),
                    //gridColumnStart: props.colStart,
                    gridColumnStart: props.gridColumnStart, 
                    gridColumnEnd: props.gridColumnEnd,
                    textAlign: "center"
                }} 
              >
            </div>
            <Modal
              fullscreen='xl-down' 
              size='xl'
              show={modal} 
              onHide={toggle} 
              className={className} 
              backdrop="static"
              keyboard={false}
              onExited={props.onDatesInsert}
            >
                <ModalHeader className="fs-3 alert-info"  closeButton>Terminassistent {element.lable} {element.name}</ModalHeader>
                <TerminCreate calDate={props.date + ' ' + tStart[props.rowStart]} daytime={props.daytime} calId={element.id} toggle={toggle} />
            </Modal>   

          </>
        );
      } else if( props.modtyp === "calendarMonth") {
        return (
          <>
            <ListGroupItem 
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}

                data-id={props.id} 


                onMouseEnter={event => onMouseOver(event)}
                onMouseOut={event => onMouseOut(event)}

                id={props.id}

            >{props.calTime}</ListGroupItem>          
          </>
        );
      }

    }


}

export default ExModalTerminAdd;


/*

          <ProgressBar
                onClick={onToggle}
                max="240"
                color="danger"
                barStyle={{
                    backgroundColor: 'red'
                }}
                className='bg-success position-relative'
                style={{
                    borderRadius: 'unset',
                    height: '23px',
                    textAlign: 'right',               
                }}
                value={(240 - props.usedtime)}
          >
            <small className="justify-content-center d-flex position-absolute w-100">{element.data[props.date][props.daytime].city}</small>
          </ProgressBar >  

*/