import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

export default function EditPayment(props) {

   //console.log('ViewPayment', props.formData);

    return (
        <Row>
            <Col className="mb-3 col-2 fs-4">
                Zahlungsart

                <Form.Select 
                    aria-label="Default select example"
                    size='lg'
                    name="paymethod"
                    value={props.formData.paymethod}
                    onChange={(e) => {
                        props.onHandleChange(e);
                    }}


                    isInvalid = {props.formData.paymethod === '' || props.formData.paymethod === undefined || props.formData.paymethod === null ? true : false}

                >
                    <option value="">Bitte wählen!</option>
                    <option value="1">Bar</option>
                    <option value="2">Karte</option>
                </Form.Select>
            </Col>
            <Col className="mb-3 me-1 col-2 fs-4">
                Zahlungsbetrag
                <Form.Control 
                    className='text-end' 
                    type="text" 
                    size='lg' 
                    name="payamount"
                    value={props.formData.payamount} 
                    isInvalid = {props.formData.payamount <= 0 ? true : false}
                    onChange={(e) => {
                         props.onHandleChange(e);
                    }}

                    onFocus={(e) => e.target.select()}

                    
                />
            </Col>
            <Col className="mb-3 me-1 col-3 fs-4">
                Rechnung/Auftrag Nr:
                <Form.Control 
                    type="text" 
                    size='lg' 
                    name="vrva"
                    value={props.formData.vrva} 
                    isInvalid = {props.formData.vrva === '' ? true : false}
                    onChange={(e) => {
                        props.onHandleChange(e);
                    }}

                    
                />
            </Col>
            <Col></Col>
            <Col className="mb-3 me-1 col-2 fs-4">
                <Button  
                    variant="danger"
                    className="mb-3 col-10 fs-3"
                    name='payment'
                    onClick={(e) => {
                        props.onHandleChange(e);
                    }}
                >keine Zahlung</Button>
            </Col>
        </Row>
        
    )

}
