import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import CalendarTimeGrid from './CalendarTimeGrid';
import CalendarItems from './CalendarItems';
import TableHeadWeek from '../TableHeadWeek';
import NavTabCalendar from './components/NavTabCalendar';
import ModalSpinner from '../ModalSpinner';
import CalendarTimeIndicator from './components/CalendarTimeIndicator';

export default class CalendarWeek extends Component {

    state = {
        isLoading: true,
        time: Date.now(),
        data: [],
        dates: { modal: "false", data: [] },
        fetchDate: null,
        calendarId: null,
        myService: null,
        myUser: null,
        myHash: '',
        pCode: '',
        token: null
    };

    constructor(props) {
        super(props);

        this.nocode = '';
        this.fetchDate = null;
        this.timeOut = null
        this.type = null;

        this.onNextDay = this.onNextDay.bind(this);
        this.onPreviousDay = this.onPreviousDay.bind(this);
        this.onChangesDay = this.onChangesDay.bind(this);

        this.onChangeCal = this.onChangeCal.bind(this);
        this.onMoveDate = this.onMoveDate.bind(this);

        this.onSetCity = this.onSetCity.bind(this);
        this.onSetPerson = this.onSetPerson.bind(this);
    
        this.onClickFree = this.onClickFree.bind(this);
        this.onSetComp = this.onSetComp.bind(this);
        this.onClickExit = this.onClickExit.bind(this);
        this.onDatesInsert = this.onDatesInsert.bind(this);

    }

    _fetchData = async () => {
        clearTimeout(this.timeOut);
        var body = "params[hash]=" + this.state.myHash;

        if(sessionStorage.getItem('myDate')) {
            body += `&params[date]=` + sessionStorage.getItem('myDate')
        }
        //calendarId
       //console.log(sessionStorage.getItem('myCal'), this.state.calendarId)
        

        if(this.state.calendarId) {
            body += `&params[calendars]=` + this.state.calendarId;
        } else if(sessionStorage.getItem('myCal')) {
            body += `&params[calendars]=` + sessionStorage.getItem('myCal')
        } 
        body += "&params[status]=1";
        body += "&params[storno]=no";

        body += "&params[limit]=1";
        body += "&token=" + this.token;

       //console.log('CalendarWeek_fetchData', Date(), body)

        const response = await fetch(localStorage.getItem('server') + '/calendar/week/', {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },            
            method: 'POST',
            body: body,
        });
        //console.log('response', response.text());
        const responseJson = await response.json();
       //console.log('responseJson', responseJson);

        if(responseJson.status && responseJson.data !== 'nochange') {
            this.setState({ 
                data: responseJson, 
                time: Date.now(), 
                myHash: responseJson.parameters.dataHash,
                isLoading: false,

            });
        } else if(!responseJson.status) {
            this.onClickExit();

        }
        this.timeOut = setTimeout(() => {
            this.updateTime();
        }, 10000);
        
    };

    _setData = async () => {
        //console.log(this.setDataAdress, this.setDataBody)

        // eslint-disable-next-line no-unused-vars
        const response = await fetch(this.setDataAdress, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },            
            method: 'POST',
            body: this.setDataBody,
        });

        //console.log("_setData",responseJson);

        //this._fetchData();
        this.setState({ isLoading: true })

    };


    onSetPerson(value, dataset) {

        this.setDataAdress = localStorage.getItem('server') + '/preset/person';

        this.setDataBody = "action=setPresetPerson";
        this.setDataBody += "&params[cal]=" + dataset.cal;
        this.setDataBody += "&params[field]=" + dataset.field;
        this.setDataBody += "&params[user]=" + value;
        this.setDataBody += "&params[day]=" + dataset.day;
        this.setDataBody += "&params[daytime]=" + dataset.daytime;
        this.setDataBody += "&token=" + this.token;



        //console.log('setDataBody', this.setDataBody);

        this._setData();
    }

    onSetCity(value, dataset) {

        this.setDataAdress = localStorage.getItem('server') + '/preset/city';

        this.setDataBody = "action=setPresetCity";
        this.setDataBody += "&params[cal]=" + dataset.cal;
        this.setDataBody += "&params[city]=" + value;
        this.setDataBody += "&params[day]=" + dataset.day;
        this.setDataBody += "&params[daytime]=" + dataset.daytime;
        this.setDataBody += "&token=" + this.token;


        //console.log('setDataBody', this.setDataBody);

        this._setData();
    }

    onMoveDate(e) {

        //console.log('onMoveDate',e.dataTransfer.getData("id"), e.target.dataset.calendar, e.target.dataset);

        this.setDataAdress = localStorage.getItem('server') + '/dates/move';

        this.setDataBody = "token=" + this.token;
        this.setDataBody += "&params[id]=" + e.dataTransfer.getData("id");
        this.setDataBody += "&params[orderid]=" + e.dataTransfer.getData("orderid");
        this.setDataBody += "&params[calendar]=" + e.dataTransfer.getData("calendar");
        this.setDataBody += "&params[date]=" + e.dataTransfer.getData("date");
        this.setDataBody += "&params[start]=" + e.dataTransfer.getData("start");
        this.setDataBody += "&params[daytime]=" + e.dataTransfer.getData("daytime");
        this.setDataBody += "&params[runtime]=" + e.dataTransfer.getData("runtime");
        this.setDataBody += "&params[targetCalendar]=" + e.target.dataset.calendar;
        this.setDataBody += "&params[targetDate]=" + e.target.dataset.date;
        this.setDataBody += "&params[targetStart]=" + e.target.dataset.start;
        this.setDataBody += "&params[targetDaytime]=" + e.target.dataset.daytime;

        //console.log('onMoveDate', this.setDataBody);
        
        this._setData();
    }

    onNextDay() {
        sessionStorage.setItem('myDate', this.state.data.action.nextDay);

        this.setState({ fetchDate: this.state.data.action.nextDay})
    }

    onPreviousDay() {
        sessionStorage.setItem('myDate', this.state.data.action.previousDay);
        this.setState({ fetchDate: this.state.data.action.previousDay})
    }

    onChangesDay(event) {
        sessionStorage.setItem('myDate', event.target.value);
        this.setState({ isLoading: true,fetchDate: event.target.value})
    }

    onChangeCal(event) {
        //console.log('onChangeCal', event);
        sessionStorage.setItem('myCal', event.target.dataset.cal);
        this.setState({ isLoading: true,calendarId: event.target.dataset.cal})
    }

    onSetComp(dataset) {
        //console.log(dataset);
        this.props.onSetComp(dataset);
    }

    updateTime() {
        //console.log('updateTime');
        this.setState({time: Date.now()});
    }

    componentDidMount() {
        if(sessionStorage.getItem('myService')) {
            const response = sessionStorage.getItem('myService');
            const responseJson = JSON.parse(response);
            this.token = responseJson.Ergebnis;
            this.type = this.props.type;
            this.setState({
                myService: responseJson,
                token: responseJson.Ergebnis,
                fetchDate: this.props.fetchDate,
                calendarId: this.props.calendarid
            });
        } else {
            this.onClickExit();
        }
    }

    onClickExit() {
        sessionStorage.clear();
        window.location.href('/');
    }

    componentDidUpdate() {
        this._fetchData();
    }

    componentWillUnmount() {
       clearTimeout(this.timeOut);
    }

    onClickFree(event) {
        //console.log(event.target.dataset);
    }

    onDatesInsert() {
       //console.log('onDatesInsert');
        this._fetchData();
    }

    render() {
        //console.log("components/calendar/CalendarWeek");
        //console.log('this.props', this.props);

        if(!this.state.isLoading) {

            const datacalendar = this.state.data.data;
            const action = this.state.data.action;
            //console.log('datacalendar', datacalendar);


            var currentTime = new Date();
    
            var nowtime = currentTime.getHours().toString().padStart(2, '0') + ":" + currentTime.getMinutes().toString().padStart(2, '0');

            const rAbteilung = ['6','8','11'];

            const myservice = JSON.parse(sessionStorage.getItem('myService'));

            var grid = '90px';
            var timeGrid = <CalendarTimeGrid nowtime={nowtime} CalendarDate={datacalendar.datum}/>;

            this.gridCol = '';
            datacalendar.period.forEach(element => {
                this.gridCol += '[ct' + element.date.replaceAll("-", "") + '] 5px [cd' + element.date.replaceAll("-", "") + '] 1fr ';
                //this.gridCol += "[cd" + element.id + "] 1fr";
                
            }); 

            if(!rAbteilung.includes(myservice['MitarbeiterAbteilung'])) {
                grid = '0fr';
                timeGrid = null;
            }

            return (

                <Card>
                    <ModalSpinner isloading={this.state.isLoading} />
                    <NavTabCalendar onChangeCal={this.onChangeCal} calendarId={datacalendar.calendar[0].id} />
                    <TableHeadWeek type="week" action={action} onChangesDay={this.onChangesDay} period={datacalendar.period} badge={'KW' + action.thisKW.kw} lable={datacalendar.calendar[0].lable} lablesmall={datacalendar.header}/>
                    <Card.Body className="p-0">
                    <div 
                        className="exp-calendar-grid mt-3" id="exp-calendar-grid" 
                        //style={{ gridTemplateColumns: grid + ' repeat(' + datacalendar.period.length + ', 2fr)' }}
                        style={{ gridTemplateColumns: grid + ' ' + this.gridCol }}
                    >
                            {timeGrid}


                            {
                                // eslint-disable-next-line array-callback-return
                                datacalendar.period.map((element, index) => {

                                    var itemData;

                                    if(datacalendar.calendar[0].data && datacalendar.calendar[0].data[element.date]) {
                                        itemData = datacalendar.calendar[0].data[element.date];
                                    } else {
                                        itemData = [];
                                    }

                                    return <CalendarTimeIndicator 
                                                key={index}
                                                type='week'
                                                itemData = {itemData}
                                                element = {element}
                                                CalendarDate={element.date} 
                                                />
                                })
                            }

                                {
                                    datacalendar.period.map((element, index) => {
                                        var itemData;

                                        if(datacalendar.calendar[0].data && datacalendar.calendar[0].data[element.date]) {
                                            itemData = datacalendar.calendar[0].data[element.date];
                                        } else {
                                            itemData = [];
                                        }
                                        return (
                                            <CalendarItems 
                                                    key={index} 
                                                    type='week' 
                                                    changeType='CalendarDay'
                                                    Lable={element.dayNameShort + ', ' + element.dayShort}
                                                    myservice={this.props.myservice} 
                                                    ColumnStart={index + 2} 
                                                    datacalendar={datacalendar} 
                                                    itemData={itemData}
                                                    itemDates={datacalendar.calendar[0].dates[element.date]}
                                                    itemDouble={datacalendar.calendar[0].dates['n'][datacalendar.datum]}
                                                    calendarId={datacalendar.calendar[0].id}
                                                    CalendarDate={element.date} 
                                                    CalendarItem={datacalendar.calendar[0]} 
                                                    person={datacalendar.person[element.date]} 
                                                    personList={datacalendar.personList[element.date]} 
                                                    personUsed={datacalendar.personUsed[element.date]} 
                                                    onSetPerson={this.onSetPerson} 
                                                    onSetCity={this.onSetCity}
                                                    onSetComp={this.onSetComp}
                                                    onMoveDate={this.onMoveDate}
                                                    onDatesInsert={this.onDatesInsert}

                                            />
                                        );
                                    })
                                }

                        </div>
                    </Card.Body>
                </Card>

            );
        } else {
            return (
                <ModalSpinner isloading={true} />
            );
        }
    }
}
