import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function ViewPayment(props) {

   console.log('ViewPayment', props);

    const payMethod = { 1 : 'Barzahlung', 2 : 'Kartenzahlung per SumUp'};
    const payAmount = parseFloat(props.formData.payamount).toFixed(2);
    return (
        <Row>
            <Col className='col-1'></Col>
            <Col className="mb-3 col-4 fs-3 text-end">
                {payMethod[props.formData.paymethod]}
            </Col>
            <Col className="mb-3 col-3 fs-3 text-start">
                {payAmount} Euro
            </Col>
        </Row>
        
    )

}
