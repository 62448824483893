import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import Row from "react-bootstrap/Row";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Table from 'react-bootstrap/Table';
import OptData from './OptData';
import Container from 'react-bootstrap/Container';
import ViewDebitor from "./ViewDebitor";
import ViewProduct from "./ViewProduct";
import ViewService from "./ViewService";
import EditPayment from "./EditPayment";
import ViewPayment from "./ViewPayment";

export default class DateCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            load: false,
            pcode: '',
            newArtikel: '',
            wizzard: null,
            buttonValue: '',
            backButtonValue: 'Kunden',
            isSubmit: false,
            formData: {
                calId: null,
                daytime: null,
                start: '',
                length: 60,
                id: '',
                sid: '',
                vrva: '',
                salutation: '',
                firstname: null,
                lastname: null,
                address: null,
                etage: null,
                zip: null,
                city: null,
                mail: null,
                mobil: null,
                telefon: null,
                name1: null,
                name2: null,
                description: '',
                dateDeliver: false,
                dateSetup: false,
                dateConnect: false,
                dateDispose: false,
                noDebitor: true,
                payment: false,
                paymethod: null,
                payamount: 0.00,
                artikel: [
                    //{ artnr: '12345678123', name: 'Testartikel', menge: 1, deliver: true, setup: false, connect: true, dispose: true, optData: []  },
                    //{ artnr: '23456789123', name: 'Artikel Test', menge: 1, deliver: true, setup: true, connect: true, dispose: true, optData: []  }
                ],
                check: {
                    firstname: true,
                    lastname: true,
                    address: true,
                    zip: true,
                    city: true,
                    contact: true,
                    description: true,
                    length: null,
                    artikel: null,
                    artikelpos: []
                }
            }
        }

        this.daytime = {1:'Vormittag', 2:'Nachmittag'};
        this.runTime = [
            [30,'30 Minuten'],
            [60,'60 Minuten'],
            [90,'1,5 Stunden'],
            [120,'2 Stunden'],
            [150,'2,5 Stunden'],
            [180,'3 Stunden'],
            [210,'3,5 Stunden'],
            [240,'4 Stunden'],
            [270,'4,5 Stunden'],
            [300,'5 Stunden'],
            [330,'5,5 Stunden'],
            [360,'6 Stunden'],
            [390,'6,5 Stunden'],
            [420,'7 Stunden'],
            [450,'7,5 Stunden'],
            [480,'8 Stunden']
        ];
        this.runTimeRange = {
            30: '30 Minuten',
            60: '60 Minuten',
            90: '1,5 Stunden',
            120: '2 Stunden',
            150: '2,5 Stunden',
            180: '3 Stunden',
            210: '3,5 Stunden',
            240: '4 Stunden',
            270: '4,5 Stunden',
            300: '5 Stunden',
            330: '5,5 Stunden',
            360: '6 Stunden',
            390: '6,5 Stunden',
            420: '7 Stunden',
            450: '7,5 Stunden',
            480: '8 Stunden'
        };
        this.form = { 
            TV: {
                Aufstellung: {name: "Aufstellung", area: ["connect","setup"], value: "value", type: "radio", check: true, options: ["Standfuß", "Wandmontage"]},
                Zusatzgeräte: {name: "Zusatzgeräte", area: ["connect","setup"], value: "value", type: "checkbox", check: false, options: ["Sat Receiver", "BlyRay / DVD", "Soundbar", "Sonstiges"]},
                Zubehör: {name: "Zubehör", area: ["deliver","connect"], value: "value", type: "checkbox", check: false, options: ["HDMI Kabel", "Antennenkabel", "Sonstiges"]},
                WLan: {name: "WLan", area: ["connect"], value: ["value"], type: "radio", check: true, options: ["Ja", "Nein"]},        
                Zusatzinfo: {name: "Zusatzinfo", area: ["deliver","connect","setup","dispose"], value: "value", check: false, type: "textarea"},
                Achtung: {name: "Info", area: ["no"], value: "Bitte vereinbarte Aufgaben anklicken >>>", type: "info"},
            },
            WWE: {
                Aufstellung: {name: "Aufstellung", area: "connect", value: "value", type: "radio", check: true, options: ["Standfuß", "Wandmontage"]},
                Info: {name: "Zusatzinfo", area: ["deliver","connect","setup","dispose"], value: "value", type: "textarea", check: false},
                Achtung: {name: "Info", area: ["no"], value: "Bitte vereinbarte Aufgaben anklicken >>>", type: "info"},
    
            },
            PC: {
                Info: {name: "Zusatzinfo", area: ["deliver","connect","setup","dispose"], value: "value", type: "textarea", check: false},
                Achtung: {name: "Info", area: ["no"], value: "Bitte vereinbarte Aufgaben anklicken >>>", type: "info"},
    
            },
            OHNE: {
                Info: {name: "Zusatzinfo", area: ["deliver","connect","setup","dispose"], value: "value", type: "textarea", check: false},
                Achtung: {name: "Info", area: ["no"], value: "Bitte vereinbarte Aufgaben anklicken >>>", type: "info"},
            }
    
    
            // key2: {name: "Aufstellung", "value": "value", "type": "select", "options": ["Stand", "Wandmontage", "option3"]},
            // key3: {name: "Name Radio", "value": "value", "type": "radio", "options": ["option1", "option2", "option3"]},
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getArt = this.getArt.bind(this);
        this.addArtikel = this.addArtikel.bind(this);
        this.onChangeOptionData = this.onChangeOptionData.bind(this);
        this.changeArtikel = this.changeArtikel.bind(this);
        this.deleteArtikel = this.deleteArtikel.bind(this);


    }

    componentDidMount() {
        //console.log("tDidMount",this.props);
        var name1 = "Vorname";
        var name2 = "Nachname";
        if(this.props.debitorData.id === "noDebitor") {
            name1 = "Name";
            name2 = "Name 2";
            this.setState({
                    load: true,
                    formData: {
                        ...this.state.formData,
                        calId: this.props.calId,
                        daytime: this.props.daytime,
                        start: this.props.calDate,
                        length: '60',
                        id: '',
                        salutation: 'XXX',
                        name1: name1,
                        name2: name2,    
                        noDebitor: false                          
                    },
            });
    
        } else {
            if(this.props.debitorData.salutation === 'Firma' || this.props.debitorData.salutation === '') {
                name1 = "Name";
                name2 = "Name 2";
            } else {
                name1 = "Vorname";
                name2 = "Nachname";
            }
            this.setState({
                load: true,
                formData: {
                    ...this.state.formData,
                    calId: this.props.calId,
                    daytime: this.props.daytime,
                    start: this.props.calDate,
                    length: '60',
                    id: this.props.debitorData.id,
                    salutation: this.props.debitorData.salutation,
                    firstname: this.props.debitorData.firstname,
                    lastname: this.props.debitorData.lastname,
                    address: this.props.debitorData.address,
                    etage:'',
                    zip: this.props.debitorData.zip,
                    city: this.props.debitorData.city,
                    mail: this.props.debitorData.mail,
                    mobil: this.props.debitorData.mobil,
                    telefon: this.props.debitorData.telefon,
                    name1: name1,
                    name2: name2,
                    description: '',
                    noDebitor: true,
                    }
              });
        }
    }

    componentDidUpdate() {

    }

    _fetchData = async () => {
        const pcode = "&code=" + this.state.pcode;
        var params = this.state.formData;
       //console.log('params', params, JSON.stringify(params.artikel[0].optData));
        
        let body = JSON.stringify(params);
        //console.log("body", body);
        body = "params=" + encodeURIComponent(body) + pcode;
        //console.log( body);
    
            const response = await fetch(localStorage.getItem('server') + '/dates/add', {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },            
                method: 'POST',
                body: body,
            });
            // eslint-disable-next-line no-unused-vars
            //console.log("response", response.clone().text());
            const responseJson = await response.json();
            //console.log("responseJson", responseJson);
            if(responseJson.status) {
               //console.log("return", responseJson);
                this.props.toggle();
                //window.location.reload(false);
    
            } else {
               //console.log("return", responseJson);

            }
        }

    _fetchArtikel = async (body) => {
      //console.log(body);
    
            const response = await fetch(localStorage.getItem('server') + '/artikel/get', {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },            
                method: 'POST',
                body: body,
            });
            // eslint-disable-next-line no-unused-vars
            //console.log(response.text());
            const responseJson = await response.json();
            let artikel = this.state.formData.artikel;
            let check = this.state.formData.check;
            const artnr = responseJson.parameters.params.id;

          //console.log("return", artnr, responseJson.status.data, responseJson);

            if(responseJson.status !== false && responseJson.data !== false) {

                //Artikel  bezeichnung und menge zu this.state.artikel hinzufügen

                artikel.push({artnr: artnr, arttyp: 'bekannt', name: responseJson.data.bezeichnung, menge: 1, deliver: false, setup: false, connect: false, dispose: false, optData: {}});
                check.artikelpos.push({select: null, name: true, optData: []});
                check.artikel = null;
                //console.log("return", responseJson);
                //return responseJson.data;
                //this.props.toggle();
                //window.location.reload(false);
    
            } else if(responseJson.status) {
                artikel.push({artnr: artnr, arttyp: 'unbekannt', name: '', menge: 1, deliver: false, setup: false, connect: false, dispose: false, optData: {}});
                check.artikelpos.push({select: null, name: null, optData: []});
                check.artikel = null;
            }        
            this.setState({
                formData: {
                    ...this.state.formData,
                    artikel: artikel,
                    check: check
                },
                newArtikel: '',
            });                
    }

    handleChange(event) {
        //console.log("handleChange", event.target.name, event.target.value);
        if(event.target.name === "salutation") {
            var name1 = "Vorname";
            var name2 = "Nachname";
            if(event.target.value === "Firma" || event.target.value === "") {
                name1 = "Name";
                name2 = "Name 2";
            }
            this.setState({
                formData: {
                    ...this.state.formData,
                    name1: name1,
                    name2: name2,            
                    [event.target.name]: event.target.value
                }
            });
    
        } else if(event.target.type === 'checkbox') {
            if(event.target.checked) {
                //console.log(event.target.name,  'checked');
                this.setState({
                    formData: {
                        ...this.state.formData,
                        [event.target.name]: true
                    }
                });
            } else {
                //console.log(event.target.name, 'no checked');
                this.setState({
                    formData: {
                        ...this.state.formData,
                        [event.target.name]: false
                    }
                });
    
            }

        } else if(event.target.name === "payamount") {

           //console.log("payamount", event.target.value);
            let value = event.target.value;
            value = value.replace(/[^0-9.,]/g, '');
           //console.log("payamount", value);
            const converted = value.replace(',', '.');
            //const numberValue = parseFloat(value);
            //console.log("payamount", numberValue);

            this.setState({
                formData: {
                    ...this.state.formData,
                    payamount: converted
                }
            });
        } else if(event.target.name === "payment" ) {

            this.setState({
                formData: {
                    ...this.state.formData,
                    payment: event.target.value,
                    paymethod: null,
                    payamount: 0.00
                }
            });
        } else if(event.target.name === "pcode" ) {
            this.setState({
                pcode: event.target.value
            });
        } else {
            this.setState({
                formData: {
                    ...this.state.formData,
                    [event.target.name]: event.target.value
                }
            });
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        const { buttonValue } = this.state;
       //console.log('Button Value:', buttonValue);        
        //const test = event.target.cloneNode(true);
        //const formData = new FormData(event.target);
        //console.log("this.state", this.state);
        //console.log("handleSubmit", formData, formData.get('id'), formData.get('submit'), test);
       //console.log(this.state.formData.payment, this.state.formData.paymethod, this.state.formData.payamount );


        switch(buttonValue) {

            
            case "Service":
            case "Lieferung":

                let falseCheck = false;
                let xCheck = this.state.formData.check;

               //console.log("handleSubmit", event.target.value, this.state.formData);
            
                if(this.state.formData.length < 30) {
                    xCheck.length = false;
                    falseCheck = true;
                } else {
                    xCheck.length = true;
                }
                if((this.state.formData.telefon === null || this.state.formData.telefon === "") && (this.state.formData.mobil === null || this.state.formData.mobil === "") && (this.state.formData.mail === null || this.state.formData.mail === "")) {
                    xCheck.contact = false;
                    falseCheck = true;
                } else {
                    xCheck.contact = true;
                }

                if(falseCheck === false) {
                    //console.log(this.state)
                    this.setState({
                        wizzard: buttonValue,
                    });
                } else {
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            check: xCheck,
                            backButtonValue: buttonValue
                        }
                    });
                }

                break;

            case "Zahlung":

                const formDataZahlung = this.state.formData;
                let falseCheckZahlung = false;
                let xCheckZahlung = this.state.formData.check;

                //console.log("handleSubmit", event.target.value, this.state.formData);

                formDataZahlung.artikel.forEach((element, key) => {
                    xCheckZahlung['artikelpos'][key]['select'] = true;
                    if(element.connect === false && element.deliver === false && element.setup === false && element.dispose === false) {
                        xCheckZahlung['artikelpos'][key]['select'] = false;
                        falseCheckZahlung = true;
                    }
                    xCheckZahlung['artikelpos'][key]['name'] = true;
                    if(element.arttyp === 'unbekannt' && element.name === '') {
                        xCheckZahlung['artikelpos'][key]['name'] = false;
                        falseCheckZahlung = true;
                    }
                
                });
                
                if(falseCheckZahlung === false) {
                    //console.log(this.state)
                    this.setState({
                        wizzard: buttonValue,
                        backButtonValue: buttonValue
                    });
                } else {
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            check: xCheckZahlung,
                            backButtonValue: buttonValue
                        }
                    });
                }

                break;

            case "Abschluss":

                const formDataAbschluss = this.state.formData;
                let falseCheckAbschluss = false;
                let xCheckAbschluss = this.state.formData.check;

               //console.log("handleSubmit", event.target.value, this.state.formData);

               if(this.state.payment) {
                if(this.state.formData.paymethod === null) {
                    falseCheckZahlung = true;
                }
                if(this.state.formData.payamount === 0.00) {
                    falseCheckZahlung = true;
                }
            }

                formDataAbschluss.artikel.forEach((element, key) => {
                    xCheckAbschluss['artikelpos'][key]['select'] = true;
                    if(element.connect === false && element.deliver === false && element.setup === false && element.dispose === false) {
                        xCheckAbschluss['artikelpos'][key]['select'] = false;
                        falseCheckAbschluss = true;
                    }
                    xCheckAbschluss['artikelpos'][key]['name'] = true;
                    if(element.arttyp === 'unbekannt' && element.name === '') {
                        xCheckAbschluss['artikelpos'][key]['name'] = false;
                        falseCheckAbschluss = true;
                    }
                  
                });
                
                if(falseCheckAbschluss === false) {
                    //console.log(this.state)
                    this.setState({
                        wizzard: buttonValue,
                        backButtonValue: buttonValue
                    });
                } else {
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            check: xCheckZahlung,
                            backButtonValue: buttonValue
                        }
                    });
                }

                break;

            case "Buchen":

                this._fetchData();


                break;
                
            case "Zurück":

                this.setState({
                    wizzard: buttonValue
                });

                break;

            default:

                this.setState({
                    wizzard: buttonValue
                });

                break;
        }
    }

    getArt(akl) {

        const myProductArea = JSON.parse(sessionStorage.getItem('myProductArea'));

        if(myProductArea[akl]) {
            return myProductArea[akl] * 1;
        } else {
            return 1;
        }
    }

    addArtikel(event) {
        /*
                              min={10000000000}
                                                        max={99999999999}
        */
        if(event.target.value.length === 11) {
            //console.log(event.target.value);
            var body = '';
            body += `params[id]=` + event.target.value
            body += "&token=999988887777666655554444333322221111";
            //console.log(body);
            this._fetchArtikel(body);
        } else {
            this.setState({
                newArtikel: event.target.value
            });
        }
    }

    changeArtikel(event) {
        let artikel = this.state.formData.artikel;
        //console.log(this.state.artikel);
        if(event.target.type === 'checkbox') {
            //console.log(event.target.dataset.key, event.target.name, event.target.checked);
            artikel[event.target.dataset.key][event.target.name] = event.target.checked;
        } else {
            //console.log(event.target.dataset.key, event.target.name, event.target.value);
            artikel[event.target.dataset.key][event.target.name] = event.target.value;

        }
        this.setState({
            formData: {
                ...this.state.formData,
                artikel: artikel
            }
        });        
        //this.setState({newArtikel: event.target.value});
    }

    deleteArtikel(event) {
        //console.log('deleteArtikel', event.target.dataset.key);
        let artikel = this.state.formData.artikel;
        let pos = event.target.dataset.key;
        artikel.splice(pos, 1);
       //console.log('deleteArtikel', pos, artikel);
        this.setState({
            formData: {
                ...this.state.formData,
                artikel: artikel
            }
        });
    }

    onChangeOptionData(pos, data, optcheck) {
       //console.log('onChangeOptionData', pos, data, optcheck);
        let artikel = this.state.formData.artikel;
        let checkdata = this.state.formData.check;
        checkdata['optData'] = optcheck;
        artikel[pos]['optData'].push(data);
        this.setState({
            formData: {
                ...this.state.formData,
                artikel: artikel,
                check: checkdata
            } 
        });
    }


    render() {

        const testEmail = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i;
        const currentTime = new Date(this.props.calDate);

        var nowtime = currentTime.getHours().toString().padStart(2, '0') + ":" + currentTime.getMinutes().toString().padStart(2, '0') + " Uhr";
    
        if(nowtime === '01:00 Uhr') {
            nowtime = this.daytime[this.props.daytime];
        } else if(nowtime === '02:00 Uhr') {
            nowtime = this.daytime[this.props.daytime];
        }
    
        var options = { weekday: 'long', day: 'numeric', month: 'long' };
    
        var nowdate = currentTime.toLocaleDateString("de-DE", options);    

        if(this.state.load === true) {

            //console.log("this.state.formData",this.state.formData);
            //console.log("this.state.wizzard",this.state.wizzard);
            switch(this.state.wizzard) {

                case "Service":

                    return (
                        <Form onSubmit={this.handleSubmit}>
                        <ModalBody className="pt-0">
                            <Row className="bg-info pt-3 pb-3">
                                <Col xs={8}>
                                    <h3 className="pt-2 ps-3">{nowdate} {nowtime}</h3> {this.state.backButtonValue} {this.state.buttonValue}
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Form.Select
                                            size="lg"
                                            name="length"
                                            type="select"
                                            onChange={this.handleChange}
                                            isInvalid={this.state.formData.length < 60}
                                            style={{borderColor: "orange", width: '100%'}}
                                            //defaultValue={60}
                                            value={this.state.formData.length}
                                            >
                                            <option value={0}>Termin länge wählen</option>
                                            {
                                                // eslint-disable-next-line array-callback-return
                                                this.runTime.map((tElement, tIndex) => {
                                                    const maxTimeOpen = 60;
                                                    if(tElement[0] <= this.props.maxTime && tElement[0] >= maxTimeOpen) {
                                                        return (
                                                            <option key={tIndex} value={tElement[0]}>{tElement[1]}</option>
                                                        )                                    
                                                    } else {
                                                        if(this.props.maxTime === undefined) {
                                                            return (
                                                                <option key={tIndex} value={tElement[0]}>{tElement[1]}</option>
                                                            )                                        
                                                        }
                                                    }
                                                })
                                            }               
                                        </Form.Select>
                                    </FormGroup>
                                </Col>
                            </Row>                                

                            <ViewDebitor formData={this.state.formData} />

      
                            <hr />
                            
                            <Row>
                                <Col md={12}>
                                    <FloatingLabel className="mb-3" label="Fehler / Auftrag / Absprachen (mindesten 10 Zeichen)">
                                        <Form.Control 
                                            className="form-control-lg mb-0 pb-0 pt-10"
                                            as="textarea" 
                                            name="description"  
                                            autoComplete="new-password"
                                            placeholder="Fehler / Auftrag / Absprachen (mindesten 10 Zeichen)" 
                                            value={this.state.formData.description} 
                                            onChange={this.handleChange} 
                                            style={{minHeight: "100px",borderColor: "orange"}} 
                                            isInvalid={!this.state.formData.check.description}
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>

                        </ModalBody>
                        <ModalFooter>
                                <Container>
                                    <Row className="" >
                                        <Button className="mb-3 col-3" type="submit" value="Kunde" name="Kunde" size="lg"  onClick={(e) => this.setState({ buttonValue: e.target.value })}>Zurück</Button>
                                        <Col className="col-6"></Col>
                                        <Button 
                                            className="mb-3 col-3" 
                                            type="submit" 
                                            value="Abschluss" 
                                            name="Abschluss" 
                                            size="lg" 
                                            disabled={this.state.formData.description.length <= 10}
                                            onClick={(e) => this.setState({ buttonValue: e.target.value })}>Weiter</Button>
                                    </Row>
                                </Container>                                
                            </ModalFooter>                        
                        </Form>
                    );

                case "Lieferung":

                    return (
                        <Form onSubmit={this.handleSubmit}>
                            <ModalBody className="pt-0">
                                <Row className="bg-info pt-3 pb-3">
                                    <Col xs={8}>
                                        <h3 className="pt-2 ps-3">{nowdate} {nowtime}</h3> {this.state.backButtonValue} {this.state.buttonValue}
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Form.Select
                                                size="lg"
                                                name="length"
                                                type="select"
                                                onChange={this.handleChange}
                                                isInvalid={this.state.formData.length < 60}
                                                style={{borderColor: "orange", width: '100%'}}
                                                //defaultValue={60}
                                                value={this.state.formData.length}
                                                >
                                                <option value={0}>Termin länge wählen</option>
                                                {
                                                    // eslint-disable-next-line array-callback-return
                                                    this.runTime.map((tElement, tIndex) => {
                                                        const maxTimeOpen = 60;
                                                        if(tElement[0] <= this.props.maxTime && tElement[0] >= maxTimeOpen) {
                                                            return (
                                                                <option key={tIndex} value={tElement[0]}>{tElement[1]}</option>
                                                            )                                    
                                                        } else {
                                                            if(this.props.maxTime === undefined) {
                                                                return (
                                                                    <option key={tIndex} value={tElement[0]}>{tElement[1]}</option>
                                                                )                                        
                                                            }
                                                        }
                                                    })
                                                }               
                                            </Form.Select>
                                        </FormGroup>
                                    </Col>
                                </Row>                                    


                                <ViewDebitor formData={this.state.formData} />

          
                                <hr />
                
                                <Row className="mb-3">
                                    <Col>
                                        <h5>Artikel</h5>
                                    <Table className="m-2" striped bordered hover >
                                        <thead className="mb-2" style={{height: '20px'}}>
                                            <tr>
                                            <th style={{width: '120px'}}>Artikel</th>
                                            <th style={{width: '250px'}}>Bezeichnung</th>
                                            <th style={{width: '60px'}} className="text-center">Menge</th>
                                            <th>Info</th>
                                            <th style={{width: '50px'}} className="text-center"><i className="bi bi-truck h3"></i></th>
                                            <th style={{width: '50px'}} className="text-center"><i className="bi bi-wrench-adjustable-circle h3"></i></th>
                                            <th style={{width: '50px'}} className="text-center"><i className="bi bi-plugin h3"></i></th>
                                            <th style={{width: '50px'}} className="text-center"><i className="bi bi-recycle h3"></i></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {   
                                                this.state.formData.artikel.map((element, index) => {
                                                    //menge: 1, deliver: true, setup: true, connect: true, dispose: true
                                                    let name = element.name;
                                                    if(element.arttyp === 'unbekannt') {
                                                        name = <Form.Control 
                                                            name="name" 
                                                            data-key={index} 
                                                            type="text" 
                                                            placeholder="Bezeichnung" 
                                                            defaultValue="" 
                                                            value={element.name}
                                                            isInvalid={!this.state.formData.check.artikelpos[index].name && this.state.formData.check.artikelpos[index].name !== null}
                                                            onChange={this.changeArtikel} />;
                                                    }
                                                       //console.log('art', this.getArt(element.artnr.substring(0, 3)));
                                                        return (
                                                            <tr key={index}>
                                                                <td className="form-control-lg">{element.artnr.substring(0, 3)} {element.artnr.substring(3, 8)} {element.artnr.substring(8, 11)}</td>
                                                                <td className="form-control-lg">{name}</td>
                                                                <td className="text-center p-0">
                                                                    <Form.Control 
                                                                        name="menge" 
                                                                        data-key={index} 
                                                                        type="text" 
                                                                        placeholder="Menge" 
                                                                        defaultValue={element.menge} 
                                                                        onChange={this.changeArtikel} 
                                                                        className="form-control-lg text-center p-0" 
                                                                        style={{width: '50px'}} />
                                                                </td>
                                                                <td>
                                                                    <OptData 
                                                                        name="optData" 
                                                                        artikel={this.state.formData.artikel[index]}
                                                                        productarea={this.getArt(element.artnr.substring(0, 3))}  
                                                                        datapos={index} 
                                                                        datacheck={this.state.formData.check.artikelpos[index].optData}
                                                                        optdata={this.state.formData.artikel[index].optData}
                                                                        art={element.artnr.substring(0, 4)} 
                                                                        onChangeOptionData={this.onChangeOptionData} 
                                                                    />
                                                                </td>
                                                                <td className="text-center">
                                                                    <Form.Check 
                                                                        name="deliver" 
                                                                        data-key={index} 
                                                                        type="checkbox" 
                                                                        defaultChecked={element.deliver} 
                                                                        isInvalid={!this.state.formData.check.artikelpos[index].select && this.state.formData.check.artikelpos[index].select !== null}
                                                                        onChange={this.changeArtikel} />
                                                                </td>
                                                                <td className="text-center">
                                                                    <Form.Check 
                                                                        name="setup" 
                                                                        data-key={index} 
                                                                        type="checkbox" 
                                                                        defaultChecked={element.setup} 
                                                                        isInvalid={!this.state.formData.check.artikelpos[index].select && this.state.formData.check.artikelpos[index].select !== null}
                                                                        onChange={this.changeArtikel} />
                                                                </td>
                                                                <td className="text-center">
                                                                    <Form.Check 
                                                                        name="connect" 
                                                                        data-key={index} 
                                                                        type="checkbox" 
                                                                        defaultChecked={element.connect} 
                                                                        isInvalid={!this.state.formData.check.artikelpos[index].select && this.state.formData.check.artikelpos[index].select !== null}
                                                                        onChange={this.changeArtikel} />
                                                                </td>
                                                                <td className="text-center">
                                                                    <Form.Check 
                                                                        name="dispose" 
                                                                        data-key={index} 
                                                                        type="checkbox" 
                                                                        defaultChecked={element.dispose} 
                                                                        isInvalid={!this.state.formData.check.artikelpos[index].select && this.state.formData.check.artikelpos[index].select !== null}
                                                                        onChange={this.changeArtikel} />
                                                                </td>
                                                                <td className="text-center">
                                                                    <div onClick={this.deleteArtikel} data-key={index} name={index}  ><i className="bi bi-trash-fill h3 text-danger" data-key={index}></i></div>
                                                                </td>                                                                
                                                            </tr>                                            
                                                        )
                                                })
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr className="table-info">
                                                <th>Artikel Nr:</th>
                                                <th className="p-0">
                                                    <Form.Control 
                                                        size="sm"
                                                        type="number" 
                                                        name="artikelnr"
                                                        autoComplete='off'
                                                        placeholder="Artikel Nr." 
                                                        step={1}
                                                        onChange={this.addArtikel}
                                                        value={this.state.newArtikel}
                                                        isInvalid={!this.state.formData.check.artikel && this.state.formData.check.artikel !== null}
                                                        className="form-control-lg p-0"
                                                        disabled={this.state.newArtikel === 11}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Bitte mindestens einen Artikel angeben!
                                                    </Form.Control.Feedback>
                                                </th>                                        
                                            </tr>
                                        </tfoot>
                                    </Table>
                                    </Col>
                                </Row>
            
                                <Row className="mb-3">
                                    <Col className="text-center"><i className="bi bi-truck h4"></i> Liefern   </Col>
                                    <Col className="text-center"><i className="bi bi-wrench-adjustable-circle h4"></i> Aufstellen/Anschließen  </Col>
                                    <Col className="text-center"><i className="bi bi-plugin h4"></i> Einrichten   </Col>  
                                    <Col className="text-center"><i className="bi bi-recycle h4"></i> Entsorgen   </Col>
                                </Row>
            
                                <hr />

            
                            </ModalBody>
                            <ModalFooter>
                                <Container>
                                    <Row className="" >
                                        <Button className="mb-3 col-3" type="submit" value="Kunde" name="Kunde" size="lg" onClick={(e) => this.setState({ buttonValue: e.target.value })}
                                        >Zurück</Button>
                                        <Col className="col-6"></Col>
                                        <Button 
                                            className="mb-3 col-3" 
                                            type="submit" 
                                            value="Zahlung" 
                                            name="Zahlung" 
                                            size="lg" 
                                            disabled={this.state.formData.artikel.length === 0}
                                            onClick={(e) => this.setState({ buttonValue: e.target.value })}
                                            >Weiter</Button>
                                    </Row>
                                </Container>                                
                            </ModalFooter>
                        </Form>
                    );    

                case "Abschluss": 

                    return (
                        <Form onSubmit={this.handleSubmit}>
                            <ModalBody className="pt-0">
                            <Row className="bg-info pt-3 pb-3">
                                    <Col xs={8}>
                                        <h3 className="pt-2 ps-3">{nowdate} {nowtime}</h3> {this.state.backButtonValue} {this.state.buttonValue}
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Form.Select
                                                size="lg"
                                                name="length"
                                                type="select"
                                                onChange={this.handleChange}
                                                isInvalid={this.state.formData.length < 60}
                                                style={{borderColor: "orange", width: '100%'}}
                                                //defaultValue={60}
                                                value={this.state.formData.length}
                                                >
                                                <option value={0}>Termin länge wählen</option>
                                                {
                                                    // eslint-disable-next-line array-callback-return
                                                    this.runTime.map((tElement, tIndex) => {
                                                        const maxTimeOpen = 60;
                                                        if(tElement[0] <= this.props.maxTime && tElement[0] >= maxTimeOpen) {
                                                            return (
                                                                <option key={tIndex} value={tElement[0]}>{tElement[1]}</option>
                                                            )                                    
                                                        } else {
                                                            if(this.props.maxTime === undefined) {
                                                                return (
                                                                    <option key={tIndex} value={tElement[0]}>{tElement[1]}</option>
                                                                )                                        
                                                            }
                                                        }
                                                    })
                                                }               
                                            </Form.Select>
                                        </FormGroup>
                                    </Col>
                                </Row>               

                                <ViewDebitor formData={this.state.formData} />

                                {
                                    this.state.formData.artikel.length === 0 
                                        ? <ViewService formData={this.state.formData} /> 
                                        : <ViewProduct formData={this.state.formData} />
                                }

                                {
                                    this.state.formData.payment
                                        ? <ViewPayment formData={this.state.formData} />
                                        : null
                                }
            
                                <hr /> 
                            </ModalBody>
                            <ModalFooter>
                                <Container>
                                    <Row className="" >
                                        <Button 
                                            className="mb-3 col-3" 
                                            type="submit" 

                                            value={this.state.formData.artikel.length === 0 ? "Service" : "Lieferung"} 
                                            name={this.state.formData.artikel.length === 0 ? "Service" : "Lieferung"} 
                                            size="lg" 
                                            onClick={(e) => this.setState({ buttonValue: e.target.value })}
                                            >Zurück</Button>
                                        <Col className="col-4"></Col>
                                        <FloatingLabel className="mb-3 col-2" label="Personal Code">
                                            <Form.Control 
                                                type="password" 
                                                name="pcode" 
                                                placeholder="Personal Code" 
                                                value={this.state.pcode}  
                                                onChange={this.handleChange}/>
                                        </FloatingLabel>                        
                                        <Button 
                                            className="mb-3 col-3" 
                                            type="submit" 
                                            value="Buchen" 
                                            name="Buchen" 
                                            size="lg" 
                                            disabled={this.state.pcode === null || this.state.pcode.length < 7 || this.state.wizzard === 'Buchen'}
                                            onClick={(e) => this.setState({ buttonValue: e.target.value })}
                                            >Buchen</Button>    
                                        <Col className="col-1"></Col>
                                    </Row>
                                </Container>                                
                            </ModalFooter>
                        </Form>
                    );

                case "Zahlung":
                    //32008107017	
                    //12220158771
                   //console.log("Zahlung", this.state.formData.payment, this.state.formData.paymethod, (this.state.formData.paymethod === null  || this.state.formData.paymethod === ''), parseFloat(this.state.formData.payamount), parseFloat(this.state.formData.payamount) === 0 );
                    //sumupmerchant://pay/1.0?amount=199.00&currency=EUR&affiliate-key=sup_afk_ldn33EHGwAX6Kzqr9XyDJdmEHzyfvjP7&title=VA0011223344&callbackfail=samplepaymentapp%3A%2F%2F&callbacksuccess=samplepaymentapp%3A%2F%2F
                    return (
                        <Form onSubmit={this.handleSubmit}>
                            <ModalBody className="pt-0">
                                <Row className="bg-info pt-3 pb-3">
                                    <Col xs={8}>
                                        <h3 className="pt-2 ps-3">{nowdate} {nowtime}</h3> {this.state.backButtonValue} {this.state.buttonValue}
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Form.Select
                                                size="lg"
                                                name="length"
                                                type="select"
                                                onChange={this.handleChange}
                                                isInvalid={this.state.formData.length < 60}
                                                style={{borderColor: "orange", width: '100%'}}
                                                //defaultValue={60}
                                                value={this.state.formData.length}
                                                >
                                                <option value={0}>Termin länge wählen</option>
                                                {
                                                    // eslint-disable-next-line array-callback-return
                                                    this.runTime.map((tElement, tIndex) => {
                                                        const maxTimeOpen = 60;
                                                        if(tElement[0] <= this.props.maxTime && tElement[0] >= maxTimeOpen) {
                                                            return (
                                                                <option key={tIndex} value={tElement[0]}>{tElement[1]}</option>
                                                            )                                    
                                                        } else {
                                                            if(this.props.maxTime === undefined) {
                                                                return (
                                                                    <option key={tIndex} value={tElement[0]}>{tElement[1]}</option>
                                                                )                                        
                                                            }
                                                        }
                                                    })
                                                }               
                                            </Form.Select>
                                        </FormGroup>
                                    </Col>
                                </Row>               

                                <ViewDebitor formData={this.state.formData} />

                                {
                                    this.state.formData.artikel.length === 0 
                                        ? <ViewService formData={this.state.formData} /> 
                                        : <ViewProduct formData={this.state.formData} />
                                }
            
                                {
                                    this.state.formData.payment

                                    ?   <EditPayment 
                                            formData={this.state.formData} 
                                            onHandleChange={this.handleChange} 
                                        />

                                    :   <div className="row justify-content-md-center">
                                            <Button  
                                                variant="warning"
                                                className="mb-3 col-10 fs-3"
                                                onClick={(e) => this.setState({
                                                    formData: {
                                                        ...this.state.formData,
                                                        payment: true 
                                                    }
                                                })}
                                            >Zahlung bei Lieferung</Button>
                                        </div>
                                }

                            </ModalBody>
                            <ModalFooter>
                                <Container>
                                    <Row className="" >
                                        <Button 
                                            className="mb-3 col-3" 
                                            type="submit" 
                                            value="Lieferung" 
                                            name="Lieferung" 
                                            size="lg"  
                                            onClick={(e) => this.setState({ buttonValue: e.target.value })}
                                        >Zurück</Button>
                                        <Col className="col-6"></Col>
                                        <Button 
                                            className="mb-3 col-3" 
                                            type="submit" 
                                            value="Abschluss" 
                                            name="Abschluss" 
                                            size="lg" 
                                            //disabled={this.state.formData.description.length <= 10}
                                            //disabled={this.state.formData.payment === true && (this.state.formData.paymethod === null && this.state.formData.payamount === 0)}
                                            disabled={
                                                this.state.formData.payment && (parseFloat(this.state.formData.payamount) === 0 || this.state.formData.paymethod === null  || this.state.formData.paymethod === ''  || this.state.formData.vrva === '')
                                            }
                                            onClick={(e) => this.setState({ buttonValue: e.target.value })}
                                        >Weiter</Button>
                                    </Row>
                                </Container>                                
                            </ModalFooter>                        
                        </Form>
                    );

                    //return (<CreateServicePayment submit={(e) => this.setState({ buttonValue: e.target.value })} />);

                case "Kunde":
                default:

                    return (
                        <Form onSubmit={this.handleSubmit}>
                            
                            <ModalBody className="pt-0">
                            <Row className="bg-info pt-3 pb-3">
                                <Col xs={8}>
                                    <h3 className="pt-2 ps-3">{nowdate} {nowtime}</h3> {this.state.backButtonValue} {this.state.buttonValue}
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Form.Select
                                            size="lg"
                                            name="length"
                                            type="select"
                                            onChange={this.handleChange}
                                            isInvalid={this.state.formData.length < 60}
                                            style={{borderColor: "orange", width: '100%'}}
                                            //defaultValue={60}
                                            value={this.state.formData.length}
                                            >
                                            <option value={0}>Termin länge wählen</option>
                                            {
                                                // eslint-disable-next-line array-callback-return
                                                this.runTime.map((tElement, tIndex) => {
                                                    const maxTimeOpen = 60;
                                                    if(tElement[0] <= this.props.maxTime && tElement[0] >= maxTimeOpen) {
                                                        return (
                                                            <option key={tIndex} value={tElement[0]}>{tElement[1]}</option>
                                                        )                                    
                                                    } else {
                                                        if(this.props.maxTime === undefined) {
                                                            return (
                                                                <option key={tIndex} value={tElement[0]}>{tElement[1]}</option>
                                                            )                                        
                                                        }
                                                    }
                                                })
                                            }               
                                        </Form.Select>
                                    </FormGroup>
                                </Col>
                            </Row>    
                            <Row className="pt-3">
                                <Col>
                                    <FloatingLabel 
                                        className="mb-3"
                                        label="Kunden Nr."
                                    >
                                        <Form.Control
                                            size="lg"
                                            type="text"
                                            name="id"
                                            placeholder="Kunden Nr."
                                            value={this.state.formData.id}
                                            readOnly={this.state.formData.noDebitor}
                                            onChange={this.handleChange}
                                        />
                                    </FloatingLabel>
                                    </Col>
                                    <Col>
                                        <FloatingLabel
                                            label="SID (wenn vorhanden)"
                                        >
                                            <Form.Control
                                                size="lg"
                                                type="text"
                                                name="sid"
                                                placeholder="SID"
                                                style={{borderColor: "orange"}}
                                                onChange={this.handleChange}
                                            />
                                        </FloatingLabel>
                                    </Col>
                                    <Col>
                                        <FloatingLabel className="mb-3" label="Auftrag Nr. (wenn vorhanden)" >
                                            <Form.Control 
                                                size="lg"
                                                type="text"
                                                name="vrva"
                                                value={this.state.formData.vrva}
                                                placeholder="Auftrag Nr."
                                                style={{borderColor: "orange"}}
                                                onChange={this.handleChange} 
                                            />
                                        </FloatingLabel>
                                    </Col>                        
                                </Row>
                                <Row>
                                    <Col>
                                        <FloatingLabel 
                                            className="mb-3"
                                            label="Anrede"
                                        >
                                            <Form.Select
                                                size="lg"
                                                id="exampleSelect"
                                                name="salutation"
                                                autoComplete="new-password"
                                                type="select"
                                                onChange={this.handleChange}
                                                value={this.state.formData.salutation}
                                                isInvalid={this.state.formData.salutation === "XXX" ? true : false}

                                            >
                                                <option value="XXX">Bitte wählen</option>
                                                <option value="">ohne</option>
                                                <option value="Herr">Herr</option>
                                                <option value="Frau">Frau</option>
                                                <option value="Firma">Firma</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                    <Col>
                                        <FloatingLabel className="mb-3" 
                                            label={this.state.formData.name1}
                                        >
                                            <Form.Control 
                                                required
                                                size="lg"
                                                type="text" 
                                                name="firstname" 
                                                autoComplete="new-password"
                                                placeholder={this.state.formData.name1}  
                                                value={this.state.formData.firstname}  
                                                onChange={this.handleChange}
                                                isInvalid={this.state.formData.firstname === "" || this.state.formData.firstname === null ? true : false}
                                            />
                                        </FloatingLabel>
                                    </Col>
                                    <Col>
                                        <FloatingLabel className="mb-3" 
                                            label={this.state.formData.name2}
                                        >
                                            <Form.Control 
                                                required = {this.state.formData.salutation === 'Herr' || this.state.formData.salutation === 'Frau' ? true : false}
                                                size="lg"
                                                type="text" 
                                                name="lastname" 
                                                autoComplete="new-password"
                                                placeholder={this.state.formData.name2}  
                                                value={this.state.formData.lastname}  
                                                onChange={this.handleChange}
                                                isInvalid={(this.state.formData.salutation === 'Herr' || this.state.formData.salutation === 'Frau') && (this.state.formData.lastname === "" || this.state.formData.lastname === null) ? true : false}
            
                                            />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={8}>
                                        <FloatingLabel className="mb-3"label="Strasse">
                                            <Form.Control 
                                                required
                                                size="lg"
                                                type="text" 
                                                name="address" 
                                                autoComplete="new-password"
                                                placeholder="Strasse"  
                                                value={this.state.formData.address}  
                                                onChange={this.handleChange}
                                                isInvalid={this.state.formData.address === "" || this.state.formData.address === null ? true : false}
                                            />
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={4}>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={2}>
                                        <FloatingLabel className="mb-3" label="PLZ">
                                            <Form.Control 
                                                required
                                                size="lg"
                                                type="text" 
                                                name="zip" 
                                                autoComplete="new-password"
                                                placeholder="PLZ"  
                                                value={this.state.formData.zip}  
                                                onChange={this.handleChange}
                                                isInvalid={this.state.formData.zip === "" || this.state.formData.zip === null ? true : false}
                                            />
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                        <FloatingLabel className="mb-3" label="Ort">
                                            <Form.Control 
                                                required
                                                size="lg"
                                                type="text" 
                                                name="city" 
                                                autoComplete="new-password"
                                                placeholder="Ort"  
                                                value={this.state.formData.city}
                                                onChange={this.handleChange}
                                                isInvalid={!this.state.formData.city === "" || this.state.formData.city === null ? true : false}
                                                />
                                        </FloatingLabel>
                                    </Col>
                                    <Col>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FloatingLabel className="mb-3" label="Telefon">
                                            <Form.Control 
                                                size="lg"
                                                type="tel" 
                                                name="telefon" 
                                                autoComplete="new-password"
                                                placeholder="Telefon" 
                                                value={this.state.formData.telefon}  
                                                onChange={this.handleChange}
                                                isInvalid={
                                                    (this.state.formData.telefon === null || this.state.formData.telefon === "") && (this.state.formData.mobil === null || this.state.formData.mobil === "") && (this.state.formData.mail === null || this.state.formData.mail === "")
                                                    ? true : false
                                                }                                                  
                                            />
                                            <Form.Control.Feedback>
                                            Mindestens eine Kontaktmöglichkeit!
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col>
                                        <FloatingLabel className="mb-3" label="Mobil / Handynummer">
                                            <Form.Control 
                                                size="lg"
                                                type="tel" 
                                                name="mobil"
                                                autoComplete="new-password"
                                                placeholder="Mobil / Handynummer" 
                                                value={this.state.formData.mobil}  
                                                onChange={this.handleChange}
                                                isInvalid={
                                                    (this.state.formData.telefon === null || this.state.formData.telefon === "") && (this.state.formData.mobil === null || this.state.formData.mobil === "") && (this.state.formData.mail === null || this.state.formData.mail === "")
                                                    ? true : false
                                                }                                         
                                            />
                                        </FloatingLabel>
                                    </Col>
                                    <Col>
                                        <FloatingLabel className="mb-3" label="eMail Adresse">
                                            <Form.Control 
                                                type="email" 
                                                name="mail"
                                                autoComplete="new-password"
                                                placeholder="eMail Adresse" 
                                                value={this.state.formData.mail}  
                                                onChange={this.handleChange}
                                                isInvalid={
                                                    (this.state.formData.telefon === null || this.state.formData.telefon === "") && (this.state.formData.mobil === null || this.state.formData.mobil === "") && !testEmail.test(this.state.formData.mail)
                                                    ? true : false
                                                }
                                            />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
            
                            </ModalBody>
                            <ModalFooter>
                                <Container>
                                    <Row className="" >
                                        <Col className="col-1"></Col>
                                            <Button 
                                                className="mb-3 col-4" 
                                                type="submit" 
                                                value="Lieferung" 
                                                name="submitButton" 
                                                size="lg" 
                                                onClick={(e) => this.setState({ buttonValue: e.target.value })}
                                            >Lieferung</Button>
                                        <Col className="col-2"></Col>
                                            <Button 
                                                className="mb-3 col-4" 
                                                type="submit" 
                                                value="Service" 
                                                name="submitButton" 
                                                size="lg" 
                                                onClick={(e) => this.setState({ buttonValue: e.target.value })}
                                            >Service</Button>
                                        <Col className="col-1"></Col>
                                    </Row>
                                </Container>
                            </ModalFooter>
                        </Form>
                    );
            }
        } else {
            return (
                <h1>Einen Moment!</h1>
            );            
        }
    }

}