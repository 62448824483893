import React from 'react';

import ModalBody from 'react-bootstrap/ModalBody';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';


const SearchDebitor = (props) => {
    //console.log("modals/ModalDebitor");
    

    return (


            <ModalBody className="pt-0">

                <Form inline="true">
                    <Row className="bg-info pt-3 pb-3">
                        <Col xs={8}>
                            <h3 className="pt-2 ps-3">{props.nowdate} {props.nowtime}</h3> {props.state.backButtonValue} {props.state.buttonValue}
                        </Col>
                    </Row>                    
                    <Row className="pt-3">
                        <Col>
                            <FloatingLabel 
                                className="mb-3"
                                label="Kundensuche (Kunden Nr., Name, Firma)"
                                
                                >
                                <Form.Control 
                                    type="text" 
                                    id="search"  
                                    onChange={props.onDebitorSearchChange}
                                    value={props.state.debitorSearch}
                                    isInvalid={props.state.debitorSearch < 60}
                                    autoComplete='off'
                                    placeholder="Kundensuche (Kunden Nr., Name, Firma)"
                                    name='debitorSearch'
                                />
                            </FloatingLabel>
                        </Col>
                    </Row>
                </Form>
                <Table hover>
                    <thead>
                        <tr>
                            <th>Nr</th>
                            <th>Name</th>
                            <th>Adresse</th>
                            <th>Ort {props.state.debitorList.length}</th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            props.state.debitorList.length > 0 ?
                            props.state.debitorList.map((element, index) => {
                                return (
                                    <tr key={index} className={element.textColor} onClick={props.onSelectDebitor}>
                                        <td data-value={element.id}>{element.id}</td>
                                        <td data-value={element.id}>{element.firstname} {element.lastname}</td>
                                        <td data-value={element.id}>{element.address}</td>
                                        <td data-value={element.id}>{element.zip} {element.city}</td>

                                    </tr>
                                );

                            })
                            : <tr>
                                <td colSpan="4" className='h3 text-center'> <br /> Bitte erst die Kundensuche nutzen! <br /> <br /> Sollte kein Kunde gefunden werden, bitte auf "neuer Kunde" klicken. <br />  <br /> </td>
                            </tr>
                        }
                        <tr>
                                <td colSpan="4" className='h3 text-center'>
                                    <Row className="pt-3">
                                        <Col></Col>   
                                        <Col md={5}>
                                            <Form.Group className="d-grid">
                                                <Button className='m-2' name="noDebitor" size='lg' onClick={props.onSelectNoDebitor}>neuer Kunde</Button>
                                            </Form.Group>
                                        </Col>

                                        <Col md={5}>
                                            <Form.Group className="d-grid">
                                                <Button className='m-2 bt btn-warning' name="noDebitor" size='lg' onClick={props.onSelectBlock} disabled>Intern blocken</Button>
                                            </Form.Group>
                                        </Col>
                                        <Col></Col>   

                                    </Row>
                                    
                                </td>
                            </tr>
                    </tbody>
                </Table>

            </ModalBody>


    )
}

export default SearchDebitor;